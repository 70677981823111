.header {
  position: sticky;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  font-family: var(--font-graphik-semibold);
  width: 100%;
  height: 112px;
  background-color: #ffffff;
  z-index: 1;
}

.logo {
  justify-self: flex-start;
  margin-top: 23px;
  margin-left: 19px;
  width: 154px;
  height: 66px;
  background-image: url(../../assets/icons/logo.webp);
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navigationButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 40px;

  .button,
  .active {
    padding: 0 20px;
    text-decoration: none;
    color: var(--main-purple);
    transition: color 0.4s ease 0s;
    user-select: none;
    cursor: pointer;
  }

  .button:hover,
  .active {
    color: #ed047a;
  }
}
