.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: calc(100% - 48px);
  min-height: calc(100% - 48px);
  background-color: #E4E2E5;
  color: #ed047a;
}

.rootRYI {
  background-color: white;
  padding-bottom: 0;
}

.topContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;

  .formContainer {
    width: 400px;

    button {
      width: 100%;
    }

    button[type="button"] {
      background-color: #FFFFFF;

      &:active {
        background-color: #FFFFFF !important;
        color: #731c49 !important;
        border-color: #731c49 !important;
      }

      &:hover {
        background-color: #FFFFFF !important;
        color: #f359a6 !important;
        border-color: #f359a6 !important;
      }
    }

    button[type="submit"] {
      &:disabled {
        background-color: #D4396D !important;
        color: #FFFFFF !important;
        border-color: #D4396D !important;
      }
    }

    :global(.ant-select-selection-item) {
      text-align: left;
    }

    :global(.ant-select-disabled) {
      :global(.ant-select-selector) {
        background-color: #FFFFFF !important;
      }
    }
  }
}

.completeText {
  max-width: 1200px;
  width: 100%;
  text-align: left;
  color: var( --main-purple);
  font-size: 30px;
  margin: 6px 0;
  font-family: var(--font-graphik-regular);
}

.title {
  width: 100%;
  max-width: 1200px;
  margin: 100px 0 5px;
  font-size: 49px;
  color: var(--main-pink);
  font-family: var(--font-cambon-bold);
}

.errorText {
  width: 100%;
  max-width: 1200px;
  margin: 100px 0 5px;
  font-size: 50px;
  color: var(--main-pink);
  font-family: var(--font-graphik-regular);
  font-weight: 500;
}