.footer {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  padding: 74px 0 69px;
  width: 100%;
  background-color: var(--main-purple);
}

.linksContainer {
  margin: 0 auto;
  a {
    font-family: var(--font-graphik-regular);
    color: white;
    font-size: 16px;
    margin-right: 20px;
  }
  a:last-child {
  margin-right: 0;
  }
}