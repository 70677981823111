.socialButtons {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 45px;
  margin: 11px 0 30px;
  gap: 11px;
  max-width: 1200px;

  .button {
    width: 45px;
    height: 45px;
    background-size: cover;
  }

  .facebook {
    @extend .button;
    background-image: url('/assets/icons/facebook-icon.webp');
  }

  .twitter {
    @extend .button;
    background-image: url('/assets/icons/twitter-icon.webp');
  }

  .email {
    @extend .button;
    background-image: url('/assets/icons/email-icon.webp');
  }

  .linkedin {
    @extend .button;
    background-image: url('/assets/icons/linkedin-icon.webp');
  }

  .instagram {
    @extend .button;
    background-image: url('/assets/icons/instagram-icon.webp');
  }
}
