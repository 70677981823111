.container {
  width: calc(100% + 48px);
  padding: 100px 0;
  margin-top: 100px;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;  
  gap: 15px;
}

.image {
    width: 60px;
    height: 60px;
    background-size: cover;
}

.faqGeneral {
  @extend .image;
  background-image: url('/assets/icons/ic-faq-general.png');
}

.faqLandlords {
  @extend .image;
  background-image: url('/assets/icons/ic-faq-homeowner.png');
}

.faqTelegraph {
  @extend .image;
  background-image: url('/assets/icons/ic-faq-telegraph.png');
}

.faqTenants {
  @extend .image;
  background-image: url('/assets/icons/ic-faq-tenant.png');
}

.installation {
  @extend .image;
  background-image: url('/assets/icons/ic-installation.png');
}

.voucher {
  @extend .image;
  background-image: url('/assets/icons/ic-voucher.png');
}

.copperNetwork {
  @extend .image;
  background-image: url('/assets/icons/ic-network.png');
}

.item {
  width: 245px;
  background-color: #f4f2f6;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: pre-wrap;

  h4 {
    line-break: auto;
    margin: 0;
    color: var(--main-purple);
 }
  p {
  font-size: 15px;
  }
}

.itemTitle {
  font-size: 26px;
  font-family: var(--font-cambon-bold);
  margin: 20px 0 0;
}

.itemText {
  line-height: 1.4;
  font-size: 18px;
  font-family: var(--font-graphik-regular);
  color: var(--main-purple)
}

.button {
  width: 70% !important;
  padding: 4px 12px;
  height: 36px;
  background-color: transparent !important;
  border: 1px solid  var(--main-pink);
  border-radius: 2px;
  color: var(--main-pink);
  font-size: 17px;
  font-family: var(--font-graphik-semibold);

  &:hover {
    background-color: rgba(255,255,255, 0.3) !important;
    opacity: 0.9;
    color: var(--main-pink) !important; 
    border-color: var(--main-pink) !important;
  }
}