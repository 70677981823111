.formTitle {
  color: var(--main-purple);
  font-size: 18px;
  font-family: var(--font-graphik-regular);
}

.form {
  margin-top: 40px;
  max-width: 1200px;
  color: var(--main-purple);

  b {
    font-family: var(--font-graphik-semibold);
  }
  
  label {
    color: var(--main-purple) !important;
    font-size: 18px !important;
    font-family: var(--font-graphik-semibold) !important;

    &::before {
        color: var(--main-purple) !important;
        margin-inline-end: 2px !important;
        position: absolute;
        right: -10px;
        font-family: var(--font-graphik-semibold) !important;
    }
  }

  p, h3 {
    text-align: left;
    font-family: var(--font-graphik-regular);
  }

  a {
    color: var(--main-pink);
    text-decoration: underline;
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-end;
}

.input {
  height: 44px;
  border-radius: 2px;
  font-size: 18px !important;
  font-family: var(--font-graphik-regular) !important;
}

.item {
  width: calc(50% - 12px);
}

.col {
  flex: 1 1 auto;
}

.title {
  font-weight: bold;
}

.checkbox {
  margin-right: 8px;

  & > span::after {
    border: 2px solid var(--main-pink) !important;
  }

  & > span > span::after {
    border: 2px solid var(--main-pink) !important;
    border-top: 0 !important;
    border-inline-start: 0 !important;
    inset-inline-start: 44% !important;
    transform: scale(2) rotate(45deg) !important;
    top: 35% !important;
  }

  & > span.ant-checkbox-checked, & > span > span {
    background-color: white !important;
    border-radius: 2px !important;
    border-color: white !important;
    width: 42px !important;
    height: 42px !important;
  }
}

.datePicker {
  width: 100%;
}

.submitButtonItem {
  margin: 40px auto 60px;
}

.submitButton {
  width: 250px;
  height: 40px;
  border-radius: 2px;
  font-size: 20px;
  font-family: var(--font-graphik-semibold);
  background-color: var(--main-pink);

  &:active {
    background-color: #731c49 !important;
  }

  &:hover {
    background-color: #f359a6 !important;
  }
}

.ryiFormSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.segmentedWrapper {
  margin: 24px auto;
  width: 50%;
}

.select {
  & > div {
      font-size: 18px !important;
      height: 44px !important;
      font-family: var(--font-graphik-regular);
      border-radius: 2px !important;

      & > span:last-child {
        font-size: 18px !important;
        font-family: var(--font-graphik-regular) !important;
        line-height: 44px !important
      }
  }
}

.messageTitle {
  font-size: 50px;
  color: var(--main-pink);
  text-align: left !important;
  margin: 0;
  line-height: 56px;
  font-family: var(--font-cambon-bold);
}

.messageText {
  color: var(--main-purple);
  font-size: 30px;
}

.agreePolicy {
  font-size: 14px;
}

.eligableTitle {
  font-size: 30px;
}

.eligableText {
  font-size: 18px;
}

.gigabitVoucher {
  display: flex;

  p {
    margin: 0;
    font-size: 16px;
  }
}

.newsletterSubscription {
  width: 50%;
  display: flex;
  height: fit-content;

  p {
    margin: 0 0 18px;
    font-size: 16px;
    font-family: var(--font-graphik-semibold) 
  }

  label {
    line-height: 0;
    height: fit-content;
  }
}

.newsletterSubscriptionBusiness {
  width: 100%;

  p {
    margin: 14px 0px;
  }
}