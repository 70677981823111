:root {
  --main-pink: #D63179;
  --main-purple: #211033;
  --light-purple: #4e2c79;
  --small-font-size: 10px;
  --regular-font-size: 16px;
  --line-height-big: 21px;
  --line-height-title: 27px;
  --font-graphik-regular: 'Graphik Regular', sans-serif;
  --font-cambon-black: 'Cambon Black', sans-serif;
  --font-cambon-bold: 'Cambon Bold', sans-serif;
  --font-graphik-semibold: 'Graphik Semibold', sans-serif;
}

body {
  margin: 0;
  font-family: 'Cambon Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

strong,
b {
  font-weight: bold;
  font-family: 'Cambon Bold', sans-serif; /* Fallback to sans-serif if 'Cambon Bold' is unavailable */
}

@font-face {
  font-family: 'Cambon Black';
  src: url('../fonts/Cambon-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cambon Bold';
  src: url('../fonts/Cambon-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cambon Light';
  src: url('../fonts/Cambon-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cambon Regular';
  src: url('../fonts/Cambon-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Bold';
  src: url('../fonts/GraphikBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Light';
  src: url('../fonts/GraphikLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Regular';
  src: url('../fonts/GraphikRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Semibold';
  src: url('../fonts/GraphikSemibold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

